﻿.checkbox,
.radio,
.dataconsent {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		margin: 0;
		top: rem(20px);
		left: rem(10px);
		opacity: 0;

		&:checked + label {

			&:before {
				color: $color-primary;
			}
		}

		&:focus + label:before {
			border-color: $color-primary;
		}
	}

	label {
		position: relative;
		display: block;
		padding: rem(11px) 0 rem(7px) rem(36px);
		margin: 0;
		cursor: pointer;
		margin: rem(0) 0 rem(9px);
		font-size: rem(16px);

		&:before {
			@include icon($icon-check);
			position: absolute;
			width: rem(18px);
			height: rem(18px);
			//border: 1px solid #ababab;
			padding: rem(4px) 0 0;
			top: rem(16px);
			left: 0;
			text-align: center;
			background: #fff;
			font-size: rem(10px);
			color: transparent;
		}

		&:focus,
		&:hover {

			&:before {
				border-color: $color-primary;
			}
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.radio {

	input {

		+ label:hover:after,
		&:focus + label:after {
			opacity: 0.5;
		}

		&:checked + label {

			&:after {
				opacity: 1;
			}
		}
	}

	label {
		margin: rem(7px) 0;

		&:before {
			content: '';
			border-radius: 50%;
		}

		&:after {
			position: absolute;
			content: '';
			width: rem(12px);
			height: rem(12px);
			top: rem(19px);
			left: rem(3px);
			background: $color-secondary;
			border-radius: 50%;
			opacity: 0;
		}
	}
}