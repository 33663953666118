﻿@import "fonts";
@import "icon";

$font-text: "Roboto", sans-serif;

$color-primary: #006443;
$color-primary-lighter: #197355;
$color-primary-light: #478f77;

$color-secondary-darker: #e1401c;
$color-secondary: #fb471f;
$color-secondary-light: #fb6f50;
$color-secondary-lighter: #fb5a36;

$color-text: #000000;

$transition-time: 0.3s;
$transition-time-form: 0.5s;

$border-radius: rem(14px);
$border-radius-big: rem(40px);