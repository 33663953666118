﻿.button {
	@include font-text-smaller;
	position: relative;
	z-index: 0;
	display: inline-flex;
	border: none;
	padding: rem(15px) rem(46px) rem(14px) rem(30px);
	margin: 0;
	text-decoration: none;
	color: #fff;
	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;
	min-height: rem(48px);
	background: $color-primary;
	transition: color $transition-time ease-in-out;
	border-radius: 0 $border-radius 0 0;

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		background: rgba(#000, 0.2);
		transform: scaleX(0);
		transform-origin: left;
		transition: transform $transition-time ease-in-out;
	}

	&:after {
		@include icon($icon-arrow-right);
		position: absolute;
		font-size: rem(10px);
		top: 50%;
		right: rem(20px);
		transform: translateY(-50%);
		transition: right $transition-time ease-in-out;
	}

	&.has-no-button {
		padding: rem(15px) rem(30px) rem(14px);

		&:after {
			display: none;
		}
	}

	&.is-white {
		color: $color-primary;
		background: #fff;
	}

	&.is-action {
		background: $color-secondary;
		color: #fff;
	}

	&.is-light {
		background: $color-primary-light;
		color: $color-primary;

		&:before {
			background: $color-secondary;
		}
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-secondary-light;
			border-radius: 50%;
			border-top: rem(4px) solid $color-secondary-lighter;
			width: rem(20px);
			height: rem(20px);
			top: rem(16px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend .button-focus;
	}

	&:hover {
		@extend .button-hover;
	}
}

.button-focus {

	&:after {
		right: rem(16px);
	}
}

.button-hover {

	&:before {
		transform: scaleX(1);
	}

	&:after {
		right: rem(16px);
	}

	&.is-light {
		color: #fff;
	}
}

.buttons {
	margin: rem(-10px);

	.button {
		margin: rem(10px);
	}

	&.has-white-button {

		.button:nth-child(2) {
			@extend .is-white;
		}
	}

	&.has-intro-button {

		.button:nth-child(1) {
			@extend .is-action;
		}
	}

	&.has-action-button {

		.button:nth-child(2) {
			@extend .is-action;
		}
	}
}
