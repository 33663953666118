@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Black.woff2') format('woff2'), url('../../fonts/Roboto-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-BlackItalic.woff2') format('woff2'), url('../../fonts/Roboto-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Bold.woff2') format('woff2'), url('../../fonts/Roboto-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Light.woff2') format('woff2'), url('../../fonts/Roboto-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Medium.woff2') format('woff2'), url('../../fonts/Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-MediumItalic.woff2') format('woff2'), url('../../fonts/Roboto-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto-Regular.woff2') format('woff2'), url('../../fonts/Roboto-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(20px);
	line-height: rem(32px);
	//letter-spacing: 0.02em;

	@media only screen and (min-width: 432px) {
		font-size: rem(19px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(18px);
	}
}

@mixin font-text-legal {
	font-size: rem(14px);
	line-height: 1.2em;
}

@mixin font-text-smaller {
	font-size: rem(16px);
	line-height: 1.2em;
}

@mixin font-text-h3 {
	font-size: rem(24px);
	line-height: rem(32px);
}

@mixin font-text-h2-smaller {
	font-size: rem(38px);
	line-height: rem(38px);

	@include breakpoint("t") {
		font-size: rem(48px);
		line-height: rem(48px);
	}
}

@mixin font-text-h2 {
	font-size: rem(48px);
	line-height: rem(48px);

	@include breakpoint("ml") {
		font-size: rem(56px);
		line-height: rem(56px);
	}
}

@mixin font-text-h1 {
	font-size: rem(48px);
	line-height: rem(48px);

	@include breakpoint("ml") {
		font-size: rem(56px);
		line-height: rem(56px);
	}

	@include breakpoint("t") {
		font-size: rem(64px);
		line-height: rem(64px);
	}
}

@mixin font-text-large {
	font-size: rem(56px);
	line-height: rem(56px);

	@include breakpoint("ml") {
		font-size: rem(72px);
		line-height: rem(72px);
	}

	@include breakpoint("t") {
		font-size: rem(96px);
		line-height: rem(96px);
	}
}