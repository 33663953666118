﻿.fileupload {

	input + label {
		display: none;
	}

	.js & {

		input {
			width: 0.1px !important;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;

			&:hover {

				+ label {
					background: mix($color-primary, #000, 90%);
				}
			}

			&:focus {

				+ label {
					background: lighten($color-primary, 5%);
				}
			}

			&:active {

				+ label {
					background: lighten($color-primary, 5%);
				}
			}
		}

		input + label {
			position: relative;
			display: block;
			padding: rem(9px) rem(18px);
			margin: 0 0 rem(24px);
			overflow: visible;
			background: $color-primary;
			color: #fff;
			cursor: pointer;
			font-weight: 500;
			text-align: center;
			transition: background $transition-time ease-in-out;
		}
	}
}
