﻿.intro {
	background: $color-primary url(../../images/intro-background.jpg) left center;
	background-size: cover;
	color: #fff;
	font-weight: 300;
	padding: 0 0 rem(97px);

	.intro__link-container {
		padding-top: rem(24px);
		padding-bottom: rem(27px);
		overflow: hidden;
		margin-bottom: rem(72px);
	}

	.intro__sub-title {
		@include font-text-h3;
		font-weight: 900;
		margin: 0 0 rem(40px);
	}

	.sub-title {
		color: #fff;
	}

	.column {
		margin: 0 0 50px;
	}

	.title {
		position: relative;
		color: #fff;

		&:after {
			position: absolute;
			content: '';
			width: rem(80px);
			height: 2px;
			bottom: rem(-25px);
			left: 0;
			background: $color-secondary;
		}
	}

	&.is-home {
		padding: rem(161px) 0 rem(97px);
	}
}
