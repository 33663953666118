﻿.input {
	width: 100%;
	min-height: rem(50px);
	padding: 0 rem(20px);
	border: 1px solid $color-primary-lighter;
	overflow: hidden;

	input {
		-webkit-appearance: none;
		border: none;
	}

	&:focus {
		border-color: lighten($color-primary-lighter, 10%);
	}
}
