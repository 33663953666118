﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-800by705 {
		@include responsive-container-psudo(800, 705);
	}

	&.is-destinations {
		@include responsive-container-psudo(1280, 853);

		img {
			display: none;

			&.small {
				display: block;
			}

			@include breakpoint("l") {
				display: block;

				&.small {
					display: none;
				}
			}
		}
	}

	&.has-rounded-corner {
		border-radius: 0 $border-radius-big 0 0;
		overflow: hidden;
	}

	&.has-placeholder {
		position: relative;
		z-index: 0;

		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: background $transition-time ease-in-out;
			background: $color-primary url(../../images/placeholder.jpg) no-repeat;
			background-size: cover;
		}

		&.hover:after {
			background-color: rgba($color-primary, 0.9);
		}
	}

	&.has-full-height {
		position: relative;
		height: 100%;

		@include breakpoint("l") {
			width: 100%;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&.is-video {
		position: relative;
		overflow: hidden;

		a[rel=modal] {
			&:after {
				@include icon($icon-play);
				position: absolute;
				width: rem(100px);
				height: rem(100px);
				border-radius: 0 $border-radius-big 0 0;
				padding: rem(31px) 0 0 rem(5px);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: rem(38px);
				color: #fff;
				background: $color-secondary;
				text-align: center;
				transition: all $transition-time ease-in-out;
				box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 1);
			}

			&:focus {

				&:after {
					background-color: #c93919;
				}
			}

			&:hover {

				&:after {
					background-color: #c93919;
				}
			}
		}
	}

	&.has-hover {
		overflow: hidden;

		img {
			transform: scale(1);
			transition: transform $transition-time ease-in-out;
		}

		&:hover {

			img {
				transform: scale(1.2);
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}
