﻿.link {
	@include font-text-smaller;
	font-weight: 500;
	position: relative;
	padding: 0 0 0 rem(28px);

	i {
		position: absolute;
		font-size: rem(10px);
		top: 0;
		left: 0;
		transition: left $transition-time ease-in-out; 
	}

	&:hover {

		i {
			left: -4px;
		}
	}
}
