﻿.languages-header-menu {
	@include font-text-smaller;
	position: relative;
	max-width: rem(600px);
	margin: rem(50px) auto;

	@include breakpoint("l") {
		margin: 0;
	}

	dl {
		position: relative;
		overflow: hidden;
		width: rem(72px);
		height: rem(50px);
		color: $color-primary;
	}

	dt {
		display: block;
		position: relative;
		text-indent: -99999px;
		width: rem(42px);
		height: rem(42px);

		&:after {
			@include icon($icon-globe);
			position: absolute;
			font-size: rem(20px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;
		}
	}

	&:hover {
		///background: lighten($color-primary, 5%);
		dt a {
			//background: $color-primary;
		}

		dd {
			display: block;
		}
	}

	dd {
		background: #fff;
		margin: 0;

		@include breakpoint("l") {
			position: absolute;
			display: none;
			top: 100%;
			right: 0;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
			@include font-text-smaller;
			font-weight: normal;
		}

		li {

			@include breakpoint("l") {

				&:first-child a {
				}

				&:last-child a {

					&:before {
						display: none;
					}
				}
			}
		}

		a {
			padding: rem(15px) rem(30px) rem(14px);
			color: $color-primary;
			display: block;
			text-decoration: none;
			border-bottom: 1px solid rgba(#fff, 0.2);

			&:before {
				position: absolute;
				content: '';
				width: calc(100% / 6);
				height: 1px;
				bottom: 0;
				left: 0;
				background: rgba($color-primary, 0.4);
			}

			&:hover {

				span:before {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}

		span {
			position: relative;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				bottom: rem(-2px);
				left: 0;
				background: $color-primary;
				transform: scaleX(0);
				transform-origin: right;
				transition: transform .4s cubic-bezier(.77, 0, .175, 1);
			}
		}
	}
}
