﻿.container {
	width: 100%;
	max-width: rem(1432px);
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-smaller {
		max-width: rem(1192px);
	}

	&.is-small {
		max-width: rem(952px);
	}

	&.intro__container {
		max-width: rem(1528px);
		padding: 0 rem(68px) 0 rem(20px);

		@include breakpoint("t") {
			padding: 0 rem(68px);
		}
	}
}
