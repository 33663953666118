﻿.social-media__container {
	display: flex;
	margin: 0;

	dd {
		margin: 0;
	}

	ul {
		overflow: hidden;
		list-style: none;
		padding: 0;

		li {
			float: left;
			margin: 0 rem(10px);
		}

		a {
			color: #fff;
			transition: color $transition-time ease-in-out;

			&:hover {
				color: $color-primary;
			}
		}

		i {

			&:before {
				font-size: rem(30px);
				line-height: rem(30px);
			}
		}
	}
}
