﻿.header-image {
	position: relative;
	z-index: 2;
	width: 100%;
	height: rem(400px);
	display: flex;
	align-items: center;

	@include breakpoint("t") {
		height: rem(500px);
	}

	&.is-large {
		min-height: calc(var(--vh) - 6.25rem);
		height: initial;
	}

	.header-image__content {
		position: relative;
		z-index: 2;
		width: 100%;
		padding: rem(32px) rem(48px) rem(50px) 0;
		pointer-events: none;

		@include breakpoint("t") {
			padding: rem(32px) rem(48px) rem(50px);
		}


		.column {
			pointer-events: initial;

			.dropdown {
				width: 100%;
				max-width: unset;
			}

			&:first-child {
				z-index: 4;

				.dropdownn__title:before {
					@include icon($icon-label);
					top: rem(19px);
				}
			}

			&:nth-child(2) {
				z-index: 3;

				.dropdownn__title:before {
					@include icon($icon-box);
					top: rem(19px);
					font-size: rem(22px);
				}
			}

			&:nth-child(3) {
				z-index: 2;

				.dropdownn__title:before {
					@include icon($icon-location);
					top: rem(20px);
					font-size: rem(19px);
				}
			}
		}
	}

	.header-image__title {
		@include font-text-h1;
		color: #fff;
		margin: 0 0 rem(56px);
		text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
	}

	.header-image__button {
		@include font-text-smaller;
		position: relative;
		z-index: 0;
		display: block;
		font-weight: 500;
		padding: rem(20px) rem(20px) rem(19px);
		color: #fff;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		background: $color-secondary;
		border-radius: 0 $border-radius 0 0;

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: rgba(#000, 0.2);
			transform: scaleX(0);
			transform-origin: left;
			transition: transform $transition-time ease-in-out;
		}

		&:hover {

			&:before {
				transform: scaleX(1);
			}
		}
	}

	.container {
		position: relative;
		z-index: 1;
	}

	img {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.skip {
		position: absolute;
		z-index: 1;
		color: #fff;
		font-size: rem(28px);
		width: rem(60px);
		height: rem(60px);
		left: 50%;
		bottom: 0;
		background: $color-primary;
		transform: translateX(-50%);
		border-radius: rem(26px) rem(26px) 0 0;

		i {
			position: absolute;
			left: 50%;
			bottom: rem(15px);
			transform: translateX(-50%);
			transition: bottom $transition-time ease-in-out;
			font-size: rem(16px);
			opacity: 0.4;
		}

		&:hover i {
			bottom: rem(11px);
		}
	}
}
