﻿.legal {
	position: relative;

	ul {
		@include font-text-legal;
		display: flex;
		flex-wrap: wrap;
		padding: rem(17px) rem(20px) rem(21px);
		margin-top: rem(-5px);
		margin-bottom: rem(-5px);
		list-style: none;
		font-style: initial;
		position: relative;
	}

	li {
		margin: rem(5px) rem(17px) rem(5px) 0;
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}

	&:before {
		position: absolute;
		content: '';
		width: (100% / 6);
		height: rem(2px);
		top: 0;
		left: 0;
		background: #fff;
	}
}
