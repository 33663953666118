﻿.form {
	width: 100%;
	overflow: hidden;

	form {
		width: 100%;
	}

	.label {
		display: block;
		margin: 0 0 rem(5px);
	}

	.singlechoice {
		margin:  0 0 rem(26px);
	}

	.field  {
		overflow: hidden;
	}

	.button {
		margin-top: rem(10px);

		@include breakpoint("laptop") {
			margin-top: 0;
		}
	}

	input {
		margin: 0 0 rem(23px);
	}

	textarea {
		margin: 0 0 rem(18px)
	}

	.email2 {
		display: none;
	}
}
