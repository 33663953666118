﻿.textarea {
	width: 100%;
	min-height: rem(160px);
	padding: rem(10px) rem(20px);
	border: 1px solid $color-primary-lighter;
	-moz-appearance: none;
	-webkit-appearance: none;
	resize: vertical;

	&:focus {
		border-color: lighten($color-primary-lighter, 10%);
	}
}
