﻿.content-block {
	position: relative;
	padding: rem(152px) 0 rem(121px);

	&:nth-of-type(odd) {
		background: rgba($color-primary, 0.06);
	}

	.title {
		margin: 0 0 rem(31px);
	}

	&.is-text-block {
		padding: rem(152px) 0 rem(121px);

		.column.is-text-column {

			@include breakpoint("l") {
				padding-right: rem(45px);
			}

			@include breakpoint("lm") {
				padding-right: rem(95px);
			}
		}
	}

	&.is-50-50 {
		padding: 0;

		.content__container {
			max-width: rem(520px);
			padding: rem(152px) rem(20px) rem(121px);
			margin: 0 auto;
		}
	}

	&.is-dropdown-list-block {
		padding: rem(152px) 0 rem(130px);
	}

	&.is-album-block {
		padding: rem(152px) 0 rem(112px);

		.column {
			margin: 0 0 rem(48px);
		}
	}

	&.is-departments {
		position: relative;
		z-index: 2;
		background: $color-primary-lighter;
		color: #fff;
		padding: rem(152px) 0 rem(146px);

		.title,
		.sub-title {
			text-align: center;
			color: #fff;
		}

		.sub-title {
			display: block;
			margin-top: rem(-24px);
			margin-bottom: rem(24px);
		}
	}

	&.is-form-block {
		background: $color-primary-lighter;
		color: #fff;

		.title {
			color: #fff;
		}
	}

	&.is-multiple-form-block {
		background: $color-primary-lighter;
		color: #fff;

		.title,
		.sub-title {
			color: #fff;
		}

		.sub-title + .buttons {
			margin-top: rem(15px);
		}

		.umbraco-forms-field.titleanddescription {
			padding: rem(48px) 0 rem(25px);
			border-bottom: 1px solid rgba(#fff, 0.5);
			margin: 0 0 rem(18px);
		}

		.form-hide__input {
			display: none;

			&:not(:checked) + .dropdown .dropdown__container {
				height: 0 !important;
				transition: height $transition-time-form ease-in-out;
			}
		}

		.dropdown__container {
			//margin-top: rem(-10px);
			transition: height $transition-time-form ease-in-out $transition-time-form;
			overflow: hidden;
		}

		.button {
			&.is-active {
				@extend .is-action;
			}
		}
	}

	&.is-destinations-block {
		padding: 0;

		.title {
			position: relative;
			padding: 0 0 rem(25px);
			margin: 0 0 rem(48px);

			&:before {
				position: absolute;
				content: '';
				width: (100% / 6);
				height: rem(2px);
				bottom: 0;
				left: 0;
				background: $color-secondary;
				transition: width $transition-time ease-in-out;
			}
		}

		.image.is-legend {
			width: 100%;
			margin-top: auto;

			img {
				width: auto;
			}
		}

		.column.is-text {
			padding: rem(153px) 0 rem(52px);
		}

		.destinations-block__text-container {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			max-width: rem(360px);
			width: 100%;
			height: 100%;
			padding: 0 rem(20px);

			@include breakpoint("l") {
				margin: 0 auto;
			}
		}

		.destinations-block__text-header {
			margin-bottom: rem(45px);
		}

		.image.is-destinations {

			img {
				object-position: left;
			}

			@include breakpoint("l") {
				display: flex;
				height: 100%;
			}
		}
	}
}
