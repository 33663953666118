﻿.contact-cards {

	.contact-cards__radio {
		display: none;

		&:not(:checked) + .contact-cards__dropdown .dropdown__container {
			height: 0 !important;
			transition: height $transition-time ease-in-out;
		}
	}

	.contact-cards__dropdown {
		overflow: hidden;
	}

	.dropdown__container {
		transition: height $transition-time ease-in-out $transition-time;
	}

	.column {
		margin: 0 0 rem(48px);
	}
}

.contact-card {
	position: relative;
	background: #fff;
	padding: rem(99px) rem(50px) rem(45px);
	color: $color-text;

	&:before {
		position: absolute;
		content: '';
		width: (100% / 6);
		height: 2px;
		top: rem(56px);
		left: 0;
		background: $color-secondary;
	}

	.contact-card__title {
		@include font-text-h3;
		color: $color-primary;
		margin: 0;
	}

	.contact-card__group {
		margin: 0 0 rem(20px);
	}

	.contact-card__email,
	.contact-card__phone,
	.contact-card__linkedin {
		display: block;
	}

	.contact-card__email,
	.contact-card__linkedin {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.contact-card__phone {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
