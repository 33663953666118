﻿.title {
	@include font-text-h2;
	color: $color-primary;
	font-weight: 900;

	span {
		display: block;
	}

	&.is-smaller {
		@include font-text-h2-smaller;
	}

	&.is-bigger {
		@include font-text-h1;
	}
}

.sub-title {
	@include font-text-h3;
	font-weight: 900;
	margin: 0;
	color: $color-primary;
}