﻿.logins {
	position: absolute;
	z-index: 0;
	width: rem(48px);
	height: rem(48px);
	top: 100%;
	right: 0;
	background: #fff;
	padding: 0 0 0 rem(48px);
	transform-origin: center;
	transform: rotate(90deg);

	&:before {
		@include icon($icon-lock);
		position: absolute;
		width: rem(48px);
		height: rem(48px);
		padding: rem(14px) 0 0;
		top: 0;
		left: 0;
		background: $color-secondary-darker;
		color: #fff;
		text-align: center;
		transform-origin: center;
		transform: rotate(-90deg);
	}

	.logins__buttons {
		position: absolute;
		left: rem(48px);
		display: flex;
	}

	.logins__button {
		display: inline-flex;
		float: left;
		position: relative;
		padding: rem(8px) rem(16px);
		font-weight: 500;
		background: $color-secondary;
		color: #fff;
		transition: background $transition-time ease-in-out;
		text-transform: uppercase;

		&:hover {
			background: $color-secondary-darker;
		}

		&:nth-child(2) {
			background: $color-secondary-lighter;

			&:hover {
				background: $color-secondary;
			}
		}
	}
}
