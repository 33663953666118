﻿.dropdown-list {
	position: relative;
	margin: 0 0 rem(20px);

	dt {
		@include font-text-h3;
		font-weight: 900;
		color: $color-primary;
		outline: none;
		background: rgba($color-primary, 0.06);
		padding: rem(25px) rem(120px) rem(25px) rem(55px);
		border-radius: 0 $border-radius-big 0 0;
		cursor: pointer;
		transition: background $transition-time ease-in-out;

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			font-size: rem(20px);
			top: rem(36px);
			right: rem(60px);
			transition: transform $transition-time ease-in-out;
		}

		.content-block:nth-of-type(odd) & {
			background: rgba($color-primary, 0.10);
		}
	}

	dd {
		overflow: hidden;
		width: 100%;
		margin: 0;
		list-style: none;
		transition: all $transition-time ease-in-out;
		background: rgba($color-primary, 0.06);
		border-top: 1px solid #fff;
	}

	.dropdown-list__content {
		padding: rem(23px) rem(55px) rem(32px);
	}

	&:not(.open) {

		dd {
			height: 0 !important;
		}
	}

	&:not(.is-clicked):focus-within,
	&.clicked.is-open {

		dt {
			background: rgba($color-primary, 0.16);

			&:after {
				transform: rotate(180deg);
			}
		}

		dd {
			background: rgba($color-primary, 0.16);
		}
	}
}
