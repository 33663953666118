﻿.content {

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	p,
	ol,
	table {
		margin: 0 0 rem(20px);
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 rem(29px);

		li {
			position: relative;
			padding: rem(13px) 0 rem(12px) rem(23px);
			border-bottom: 1px solid rgba(#000, 0.5);

			&:before {
				position: absolute;
				content: '';
				width: rem(5px);
				height: rem(5px);
				border-radius: 50%;
				top: rem(25px);
				left: 0;
				background: #000;
			}

			&:first-child {
				border-top: 1px solid rgba(#000, 0.5);
			}
		}
	}

	p,
	ol,
	ul,
	table {

		+ .sub-title {
			margin-top: rem(29px);
		}
	}

	+ .buttons {
		margin-top: rem(28px);
		margin-bottom: rem(19px);
	}

	+ .image {
		margin-top: rem(39px);
		margin-bottom: rem(39px);
	}

	+ .columns {
		margin-top: rem(48px);
	}

	+ .form {
		padding-top: rem(14px);
		border-top: 1px solid rgba(#fff, 0.5);
		margin-top: rem(38px);
	}
}
