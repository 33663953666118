﻿.banner-blocks {
	position: relative;

	a:hover {

		.banner-block__content {
			padding: 0 0 rem(60px);
		}

		.banner-block__title {

			&:before {
				width: (100% / 3);
			}
		}

		.banner-block__button {
			opacity: 1;
			bottom: rem(10px);
		}
	}

	.banner-block__content {
		position: absolute;
		width: 100%;
		padding: 0;
		bottom: rem(16px);
		left: 0;
		transition: padding $transition-time ease-in-out;
	}

	.banner-block__title {
		position: relative;
		color: #fff;
		padding: rem(27px) rem(40px) rem(27px);
		margin: 0 0 rem(40px);
		text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);

		@include breakpoint("lm") {
			padding: rem(27px) rem(80px) rem(27px);
		}

		&:before {
			position: absolute;
			content: '';
			width: (100% / 6);
			height: rem(2px);
			bottom: 0;
			left: 0;
			background: $color-secondary;
			transition: width $transition-time ease-in-out;
		}
	}

	.banner-block__button {
		position: absolute;
		bottom: rem(-60px);
		left: rem(80px);
		opacity: 0;
		transition: all $transition-time ease-in-out;
	}
}
