﻿#nav-toggler {
	display: none;

	&:checked + .nav-toggler {
		@extend .is-active;
	}

	&:checked ~ .nav.is-side-nav {
		opacity: 1;
		right: 0;

		&:before {
			pointer-events: initial;
		}
	}
}

.nav.is-side-nav {
	position: fixed;
	z-index: 1000;
	max-width: rem(476px);
	width: 100%;
	height: 100vh;
	padding: rem(113px) 0 rem(80px);
	top: 0;
	right: rem(-100%);
	//right: 0;
	background: $color-primary;
	color: #fff;
	opacity: 0;
	//opacity: 1;
	transition: all $transition-time ease-in-out;
	font-weight: 600;
	overflow-y: auto;

	.nav-container {
		list-style: none;
		padding: 0;

		> li:first-child > a {
			border-top: 1px solid rgba(#fff, 0.2);
		}
	}

	.navbar-container {
	}

	li {
		position: relative;
		padding: 0;

		input {
			display: none;

			&:checked ~ label i:before {
				@include icon($icon-arrow-up);
			}

			&:checked ~ ul {
				display: block;
			}
		}

		label {
			position: absolute;
			z-index: 2;
			width: rem(60px);
			height: rem(59px);
			top: 0;
			right: 0;
			border-top: 1px solid rgba(#fff, 0.2);
			border-left: 1px solid rgba(#fff, 0.2);
			cursor: pointer;
			background: $color-primary;

			i:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				background: lighten($color-primary, 5%);
			}
		}

		ul {
			//@include font-size-16;
			//background: $color-primary-hover;
			padding: 0;
			list-style: none;
			display: none;

			a {
				padding: rem(10px) rem(80px) rem(9px);
				background: lighten($color-primary, 2.5%);

				&:focus {
					background: lighten($color-primary, 7.5%);
				}

				&.is-active {
					background: lighten($color-primary, 5%);
				}

				&:hover {
					background: lighten($color-primary, 5%);
				}
			}
		}
	}

	a {
		display: block;
		width: 100%;
		padding: rem(13px) rem(60px) rem(13px);
		border-bottom: 1px solid rgba(#fff, 0.2);
		transition: all $transition-time ease-in-out;

		&:focus {
			background: lighten($color-primary, 5%);
		}

		&.is-active {
			background: lighten($color-primary, 2.5%);
		}

		&:hover {
			background: lighten($color-primary, 2.5%);
		}
	}


	&:before {
		position: fixed;
		z-index: -1;
		content: '';
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(#000, 0.25);
		pointer-events: none;
	}

	.languages {
		@extend .languages-side-menu;
		z-index: 2;
	}
}