﻿.contact-card-drop-down {
	position: relative;
	z-index: 1;
	background: #fff;
	color: $color-primary;
	margin: 0 auto rem(48px);
	width: 100%;
	max-width: rem(432px);

	.contact-card-drop-down__title,
	.contact-card-drop-down__item {
		display: block;
	}

	.contact-card-drop-down__title {
		position: relative;
		font-weight: 500;
		padding: rem(15px) rem(56px) rem(11px);
		cursor: pointer;

		&:before {
			@include icon($icon-phone);
			position: absolute;
			top: rem(17px);
			left: rem(18px);
			font-size: rem(22px);
			color: $color-secondary;
		}

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: rem(24px);
			right: rem(21px);
			font-size: rem(13px);
			transition: transform $transition-time ease-in-out;
		}
	}

	.contact-card-drop-down__description {
		width: 100%;
		padding: 0;
		margin: 0;
		overflow: hidden;
		transition: height $transition-time ease-in-out;
		background: #fff;

		.js & {
			position: absolute;
		}
	}

	.contact-card-drop-down__content {
		@include font-text-smaller;
		padding: 0;
		list-style: none;
	}

	.contact-card-drop-down__item {
		position: relative;
		padding: rem(16px) rem(56px) rem(13px);
		transition: background $transition-time ease-in-out;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: (100% / 6);
			height: 1px;
			top: 0;
			left: 0;
			background: $color-primary;
			opacity: 0.4;
		}

		&:hover {
			background: rgba($color-primary, 0.1);
		}
	}

	&:not(.open) {

		.contact-card-drop-down__description {

			.js & {
				height: 0 !important;
			}
		}
	}

	&:not(.is-clicked):focus-within,
	&.is-clicked.open {

		.contact-card-drop-down__title {

			&:after {
				transform: rotate(180deg);
			}
		}
	}
}
