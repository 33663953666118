﻿.call-to-action {
	position: relative;
	display: flex;
	z-index: 0;
	min-height: rem(600px);

	@include breakpoint(t) {
		min-height: rem(700px);
	}

	@include breakpoint(l) {
		min-height: rem(800px);
	}

	.call-to-action__image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.call-to-action__content {
		padding: rem(40px) rem(20px) rem(52px) rem(45px);
		margin: auto 0 0;

		@include breakpoint("ml") {
			padding: rem(40px) rem(20px) rem(52px) rem(65px);
		}

		@include breakpoint("t") {
			padding: rem(40px) rem(40px) rem(62px) rem(85px);
		}

		@include breakpoint("l") {
			padding: rem(40px) rem(60px) rem(72px) rem(115px);
		}

		@include breakpoint("lm") {
			max-width: 50%;
		}

		.button {
			margin-left: rem(125px);

			@include breakpoint("ml") {
				margin-left: rem(165px);
			}

			@include breakpoint("t") {
				margin-left: rem(205px);
			}

			@include breakpoint("l") {
				margin-left: rem(245px);
			}
		}

		&:before {
			position: absolute;
			content: '';
			width: rem(130px);
			height: rem(2px);
			bottom: rem(75px);
			left: 0;
			background: $color-secondary;

			@include breakpoint("ml") {
				width: rem(190px);
				bottom: rem(75px);
			}

			@include breakpoint("t") {
				width: rem(250px);
				bottom: rem(85px);
			}

			@include breakpoint("l") {
				width: rem(320px);
				bottom: rem(95px);
			}
		}
	}

	.call-to-action__title {
		@include font-text-large;
		font-weight: 900;
		color: #fff;
		margin: 0 0 rem(41px);
		text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
	}
}
