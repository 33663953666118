﻿.nav.is-header-nav {
	position: absolute;
	z-index: 2;
	font-weight: 500;
	display: none;
	color: $color-primary;
	width: 100%;
	padding: 0 rem(20px) 0 rem(285px);
	top: 50%;
	transform: translateY(-50%);
	transition: opacity $transition-time ease-in-out, visibility $transition-time ease-in-out;

	@include breakpoint("l") {
		display: block;
	}

	.nav-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
	}

	.nav-item {

		label,
		input {
			display: none;
		}

		&.is-active,
		&:hover {

			> a {
				z-index: 2;

				> span:before {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}

		&:hover {

			ul {
				display: block;
			}
		}

		&.has-children:hover {

			> a {
				//background: $color-primary-hover;
			}
		}
	}

	a {
		position: relative;
		display: block;
		color: inherit;
		text-decoration: none;
		padding: rem(12px) rem(20px) rem(11px);
		white-space: nowrap;
	}

	span {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			bottom: rem(-2px);
			left: 0;
			background: $color-primary;
			transform: scaleX(0);
			transform-origin: right;
			transition: transform .4s cubic-bezier(.77, 0, .175, 1);
		}
	}

	.nav-item {
		position: relative;

		ul {
			@include font-text-smaller;
			position: absolute;
			background: #fff;
			padding: 0;
			min-width: 100%;
			list-style: none;
			box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
			display: none;

			.nav-item {

				a {
					position: relative;
					padding: rem(15px) rem(30px) rem(14px);
					font-weight: 400;

					&:before {
						position: absolute;
						content: '';
						width: calc(100% / 6);
						height: 1px;
						bottom: 0;
						left: 0;
						background: rgba($color-primary, 0.4);
					}

					&:hover {

						span:before {
							transform: scaleX(1);
							transform-origin: left;
						}
					}
				}

				&:first-child a {
					padding-top: rem(17px);
				}

				&:last-child a {
					padding-bottom: rem(12px);

					&:before {
						display: none;
					}
				}
			}
		}

		&:last-of-type {

			ul {
				right: 0;
			}
		}
	}

	.languages {
		@extend .languages-header-menu;
	}
}
