﻿.logo {
	display: block;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;

	&.is-brand {
		width: rem(245px);
		height: rem(54px);
		background: url(../../images/logo-van-duijn-transport.svg) no-repeat center;

		&.is-white {
			width: rem(235px);
			height: rem(50px);
			background: url(../../images/logo-van-duijn-transport-white.svg) no-repeat center;
		}
	}
}
